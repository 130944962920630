import React, { Component } from 'react';
import {
  array,
  arrayOf,
  bool,
  func,
  object,
  shape,
  string,
  oneOf,
  PropTypes,
} from 'prop-types';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  propTypes,
} from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import {
  createResourceLocatorString,
  findRouteByRouteName,
} from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
  getExternalUserReviews,
  calculateAverageUserReview,
  totalUserReviewCount,
} from '../../util/data';
import { timestampToDate, calculateQuantityFromHours } from '../../util/dates';
import { richText } from '../../util/richText';
import {
  getMarketplaceEntities,
  getListingsById,
} from '../../ducks/marketplaceData.duck';
import {
  manageDisableScrolling,
  isScrollingDisabled,
} from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  Modal,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  Menu,
  MenuLabel,
  MenuContent,
  InlineTextButton,
  MenuItem,
  IconCollection,
  ListingAvatar,
  SimilarItems,
  Reviews,
} from '../../components';
import { EnquiryForm } from '../../forms';
import { TopbarContainer, NotFoundPage } from '../../containers';

import {
  sendEnquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.module.css';
import classNames from 'classnames';
import { closeListing } from '../ManageListingsPage/ManageListingsPage.duck';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import '../../assets/react-loading-skeleton.css';
import { getDistanceFromLocation } from '../SearchPage/SearchPage.duck';
import { getDoLater } from '../../util/dataExtractor';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const getCustomerCommission = lineItems => {
  // Find the line items
  const dayLineItems = lineItems?.filter(
    item => item?.code === 'line-item/day'
  );
  const amounts = dayLineItems?.map(item => item?.lineTotal?.amount / 100);

  // Find the line item with customer commission and day price
  const commissionLineItem = lineItems?.find(
    item => item?.code === 'line-item/customer-commission'
  );
  const commissionAmount =
    commissionLineItem?.lineTotal?.amount / 100 +
    amounts?.reduce((a, b) => a + b, 0);
  return commissionAmount && commissionAmount.toFixed(0);
};

const getProviderCommission = lineItems => {
  // Find the line items
  const dayLineItems = lineItems?.filter(
    item => item?.code === 'line-item/day'
  );
  const amounts = dayLineItems?.map(item => item?.lineTotal?.amount / 100);

  // Find the line item with customer commission and day price
  const commissionLineItem = lineItems?.find(
    item => item?.code === 'line-item/provider-commission'
  );
  const commissionAmount =
    commissionLineItem?.lineTotal?.amount / 100 +
    amounts?.reduce((a, b) => a + b, 0);
  return commissionAmount && commissionAmount.toFixed(0);
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      viewAll: true,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  handleSubmit(values) {
    const {
      currentUser,
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
      lineItems,
    } = this.props;
    const customerCommission = getCustomerCommission(lineItems);
    const providerCommission = getProviderCommission(lineItems);

    const providerCommissionLineItem = (
      lineItems?.find(item => item?.code === 'line-item/provider-commission')
        .lineTotal.amount / 100
    ).toFixed(0);
    const customerCommissionLineItem = (
      lineItems?.find(item => item?.code === 'line-item/customer-commission')
        .lineTotal.amount / 100
    ).toFixed(0);
    const listingId = new UUID(params.id.split('?')[0]);
    const listing = getListing(listingId);
    const {
      bookingStartTime,
      bookingEndTime,
      diffBetweenDays,
      selectedTime,
      selectedPrice,
      bookingEndDate,
      bookingStartDate,
      ...restOfValues
    } = values;
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = bookingEndTime
      ? timestampToDate(bookingEndTime)
      : timestampToDate(bookingStartTime);
    const bookingDates = {
      bookingStart: bookingStart,
      bookingEnd:
      diffBetweenDays === 0 
      ? moment(bookingStart)
          .add(6, 'hours')
          .toDate()
      :
        diffBetweenDays === 1
          ? moment(bookingStart)
              .add(24, 'hours')
              .toDate()
          : diffBetweenDays > 1
          ? moment(bookingStart)
              .add(diffBetweenDays * 24, 'hours')
              .toDate()
          : bookingEnd,
    };
    const bookingData = {
      ...restOfValues,
      quantity: calculateQuantityFromHours(bookingStart, bookingEnd),
      selectedTime:
        selectedTime === 'sixHours' && diffBetweenDays > 0
          ? 'day'
          : selectedTime,
      diffBetweenDays,
      selectedPrice:
        selectedTime === 'sixHours' && diffBetweenDays > 0
          ? Math.round(listing.attributes.price.amount / 100)
          : selectedPrice,
      providerCommission,
      customerCommission,
      providerCommissionLineItem,
      customerCommissionLineItem,
      bookingDates,
      bookingStartDate: bookingStartDate.toISOString(),
      bookingEndDate: bookingEndDate.toISOString(),
    };
    const initialValues = {
      listing,
      bookingData,
      bookingDates,
      confirmPaymentError: null,
    };

    const saveToSessionStorage = !this.props.currentUser;

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    const didDoLater = getDoLater(currentUser);
    const checkoutURL = createResourceLocatorString(
      'CheckoutPage',
      routes,
      { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
      {}
    );
    if (didDoLater) {
      history.push(
        createResourceLocatorString(
          'IdentityPage',
          routes,
          {},
          { redirect: checkoutURL }
        )
      );
    } else {
      // Redirect to CheckoutPage
      history.push(checkoutURL);
    }
  }

  onContactUser() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
    } = this.props;

    if (!currentUser) {
      const state = {
        from: `${location.pathname}${location.search}${location.hash}`,
      };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, {
        enquiryModalOpenForListingId: params.id,
      });

      // signup and return back to listingPage.
      history.push(
        createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
        state
      );
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id.split('?')[0]);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString(
            'OrderDetailsPage',
            routes,
            { id: txId.uuid },
            {}
          )
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      onFetchTimeSlots,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      monthlyTimeSlots,
      filterConfig,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onCloseListing,
      pageListings,
      timeSlots,
      viewport,
    } = this.props;

    const isMobile = viewport && viewport.width < 767;
    const listingId = new UUID(rawParams.id.split('?')[0]);
    const isPendingApprovalVariant =
      rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));
    const listingSlug =
      rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id &&
      currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage =
      pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return (
        <NamedRedirect
          name="ListingPage"
          params={params}
          search={location.search}
        />
      );
    }
    const search =
      this.props.location.pathname.indexOf('?') > -1
        ? this.props.location.pathname.split('?')[1]
        : this.props.location.search;
    const query = new URLSearchParams(search);
    const searchLatLng =
      isNaN(parseFloat(query.get('lng'))) || isNaN(parseFloat(query.get('lat')))
        ? [
            currentUser?.attributes?.profile?.protectedData?.origin?.lat,
            currentUser?.attributes?.profile?.protectedData?.origin?.lng,
          ]
        : [parseFloat(query.get('lat')), parseFloat(query.get('lng'))];
    const { lat, lng } =
      (currentListing &&
        currentListing.id &&
        currentListing.attributes.geolocation) ||
      {};
    const distance = getDistanceFromLocation(searchLatLng, [lat, lng]);
    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData,
    } = currentListing.attributes;
    const category = publicData?.category;
    const link = publicData?.storeLink;
    const urlLink =
      link && link.split(':')[0] === 'https' ? `${link}` : `https://${link}`;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage
        id="ListingPage.bookingTitle"
        values={{ title: richTitle }}
      />
    );

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });
      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable &&
      currentListing.author.id.uuid === currentUser.id.uuid;
    const showContactUser =
      authorAvailable && (!currentUser || (currentUser && !isOwnListing));
    const id = currentListing.id.uuid;
    const slug = createSlug(title);
    const editListingLinkType = LISTING_PAGE_PARAM_TYPE_EDIT;
    // const menuItemClasses = classNames(css.menuItem, {
    //   [css.menuItemDisabled]: !!actionsInProgressListingId,
    // });
    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // Retrieve external reviews for the author.
    const authorExternalReviews = getExternalUserReviews(ensuredAuthor);
    const hasExternalReviews = authorExternalReviews?.count > 0;

    // Calculate the average review considering both local and external reviews for the user.
    const averageReview = calculateAverageUserReview(
      reviews,
      authorExternalReviews
    );

    // Calculate the total count of reviews, considering both local and external reviews.
    const reviewCount = totalUserReviewCount(
      reviews.length,
      authorExternalReviews
    );

    const showReviews = hasExternalReviews ? reviews?.length > 0 : !!reviews;

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed =
        currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );
    const certificateOptions = findOptionsForSelectFilter(
      'certificate',
      filterConfig
    );
    const currentUserListingCategory =
      currentListing && currentListing?.attributes?.publicData?.category;
    const filterDataTrue = pageListings && pageListings.length > 0;

    const filterData =
      filterDataTrue &&
      pageListings.filter(
        item =>
          item?.attributes?.publicData?.category ===
            currentUserListingCategory &&
          item?.id?.uuid !== currentListing?.id?.uuid
      );
    const address = publicData?.location?.address;
    const formetZipLocation = address?.split(',');
    const splitted = address && address?.split(',');
    const stateName =
      splitted && splitted.length == 4
        ? splitted[2]
        : splitted && splitted.length == 5
        ? splitted[3]
        : splitted && splitted[1];
    const cityName =
      splitted && splitted.length == 4
        ? splitted[1]
        : splitted && splitted.length == 5
        ? splitted[2]
        : splitted && splitted[0];
    const stateName1 =
      stateName?.split(' ')[1] + ' ' + stateName?.split(' ')[2];
    const finalState =
      stateName?.split(' ').length == 4 ? stateName1 : stateName?.split(' ')[1];

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={description}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: description,
          name: schemaTitle,
          image: schemaImages,
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div
              className={classNames(
                css.listingPageContainer,
                isOwnListing ? null : css.otherListingPageContainer
              )}
            >
              <div className={css.listingSliderContainer}>
                <div className={css.leftContainer}>
                  <SectionImages
                    viewport={viewport}
                    title={title}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                    imageCarouselOpen={this.state.imageCarouselOpen}
                    onImageCarouselClose={() =>
                      this.setState({ imageCarouselOpen: false })
                    }
                    handleViewPhotosClick={handleViewPhotosClick}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                  {isOwnListing ? null : (
                    <>
                      <div>
                        <div
                          className={classNames(
                            css.headingMain,
                            css.otherHeadingMain
                          )}
                        >
                          <div>
                            <SectionHeading
                              priceTitle={priceTitle}
                              formattedPrice={formattedPrice}
                              richTitle={richTitle}
                              selectedCategory={category}
                              publicData={category}
                              isOwnListing={isOwnListing}
                              listingCertificate={
                                publicData ? publicData.certificate : null
                              }
                              certificateOptions={certificateOptions}
                              hostLink={hostLink}
                              showContactUser={showContactUser}
                              onContactUser={this.onContactUser}
                            />

                            <div className={css.locationWrapper}>
                              <IconCollection name="LISTING_MAP_LOCATION" />
                              <p className={css.locationText}>
                                {cityName ? cityName : null},{' '}
                                {finalState ? finalState : null}{' '}
                                {distance ? (
                                  <>
                                    (
                                    {distance.toFixed(0) <= 10
                                      ? distance.toFixed(1)
                                      : distance.toFixed(0)}{' '}
                                    mi)
                                  </>
                                ) : (
                                  <Skeleton width={50} height={10} />
                                )}
                              </p>
                            </div>
                          </div>
                          <div className={css.listingRatingContainer}>
                            <div className={css.listingRating}>
                              <IconCollection name="RATING_STAR" />
                              <h6 className={css.ratingText}>
                                {averageReview} ({reviewCount})
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      {isMobile ? (
                        <BookingPanel
                          description={description}
                          className={css.bookingPanel}
                          listing={currentListing}
                          isOwnListing={isOwnListing}
                          unitType={unitType}
                          onSubmit={handleBookingSubmit}
                          title={bookingTitle}
                          authorDisplayName={authorDisplayName}
                          onManageDisableScrolling={onManageDisableScrolling}
                          monthlyTimeSlots={monthlyTimeSlots}
                          onFetchTimeSlots={onFetchTimeSlots}
                          timeSlots={timeSlots}
                          onFetchTransactionLineItems={
                            onFetchTransactionLineItems
                          }
                          lineItems={lineItems}
                          isMobile={isMobile}
                          fetchLineItemsInProgress={fetchLineItemsInProgress}
                          fetchLineItemsError={fetchLineItemsError}
                          publicData={publicData}
                          distanceInMiles={distance}
                        />
                      ) : null}
                      <div>
                        <ListingAvatar
                          currentAuthor={currentListing.author}
                          listingCertificate={
                            publicData ? publicData.certificate : null
                          }
                          certificateOptions={certificateOptions}
                          hostLink={hostLink}
                          reviews={reviews}
                          showContactUser={showContactUser}
                          averageReview={averageReview}
                          reviewCount={reviewCount}
                        />
                      </div>
                      <div>
                        <div
                          className={classNames(
                            css.learnMoreSection,
                            css.learnMoreSectionMobile
                          )}
                        >
                          <div className={css.freeCancellation}>
                            <IconCollection name="THUMPS_UP" />
                            <h6>
                              <FormattedMessage id="ManageListingCard.freeCancellation" />
                            </h6>
                          </div>
                          <a href="">
                            <FormattedMessage id="TransactionPanel.comingSoon" />
                          </a>
                        </div>
                        <div
                          className={classNames(
                            css.officialBrandSection,
                            css.officialBrandSectionMobile
                          )}
                        >
                          <div className={css.officialBrand}>
                            <IconCollection name="BRAND_STORE" />
                            <h6>
                              <FormattedMessage id="TransactionPanel.brandStore" />
                            </h6>
                          </div>
                          <a href={urlLink} target="_blank">
                            <IconCollection name="BRAND_STORE_LINK" />
                          </a>
                        </div>
                        <SectionDescriptionMaybe
                          description={description}
                          showReviews={showReviews}
                        />

                        <div className={css.mainContentMobile}>
                          <SectionMapMaybe
                            geolocation={geolocation}
                            publicData={publicData}
                            listingId={currentListing.id}
                            distanceInMiles={distance}
                            showReviews={showReviews}
                          />
                        </div>

                        {showReviews ? (
                          <div className={css.reviewContainer}>
                            <div className={css.reviewTitle}>
                              <h4>
                                <FormattedMessage id="ManageListingCard.topReviews" />
                              </h4>
                              {reviews && reviews.length > 5 ? (
                                <a>
                                  <FormattedMessage id="ManageListingCard.viewAll" />
                                </a>
                              ) : null}
                              <NamedLink
                                name={!!ensuredAuthor?.attributes?.profile?.publicData?.businessName ? "BusinessProfileOthersPage" : "ProfilePage"}
                                params={{
                                  id: currentListing?.author?.id?.uuid,
                                }}
                              >
                                <span className={css.viewAllText}>
                                  View all
                                </span>
                              </NamedLink>
                            </div>
                            {fetchReviewsError ? (
                              <p className={css.error}>
                                <FormattedMessage id="ListingPage.reviewsError" />
                              </p>
                            ) : null}
                            {reviews?.length ? (
                              <Reviews
                                viewAll={this.state.viewAll}
                                reviews={reviews}
                                user={ensuredAuthor}
                              />
                            ) : fetchReviewsError ? null : (
                              'No reviews yet'
                            )}
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>

                {isOwnListing ? (
                  <div className={css.contentContainer}>
                    <div className={css.headingMain}>
                      <SectionHeading
                        priceTitle={priceTitle}
                        formattedPrice={formattedPrice}
                        selectedCategory={category}
                        isOwnListing={isOwnListing}
                        richTitle={richTitle}
                        listingCertificate={
                          publicData ? publicData.certificate : null
                        }
                        certificateOptions={certificateOptions}
                        hostLink={hostLink}
                        showContactUser={showContactUser}
                        onContactUser={this.onContactUser}
                      />
                      <div className={css.menubar}>
                        <Menu contentPosition="left" useArrow={false}>
                          <MenuLabel
                            className={css.menuLabel}
                            isOpenClassName={css.listingMenuIsOpen}
                          >
                            <div className={css.iconWrapper}>
                              <IconCollection name="LISTING_MENU_ICON" />
                            </div>
                          </MenuLabel>
                          <MenuContent rootClassName={css.menuContent}>
                            <MenuItem key="close-listing">
                              <InlineTextButton
                                onClick={event => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                  onCloseListing(currentListing.id);
                                }}
                              >
                                <NamedLink
                                  className={css.manageLink}
                                  name="ManageListingPage"
                                >
                                  <FormattedMessage id="ManageListingCard.pause" />
                                </NamedLink>
                              </InlineTextButton>
                            </MenuItem>
                            <MenuItem key="edit-listing">
                              <NamedLink
                                className={css.manageLink}
                                name="EditListingPage"
                                params={{
                                  id,
                                  slug,
                                  type: editListingLinkType,
                                  tab: 'description',
                                }}
                              >
                                <FormattedMessage id="ManageListingCard.edit" />
                              </NamedLink>
                            </MenuItem>
                          </MenuContent>
                        </Menu>
                      </div>
                    </div>

                    <div className={css.mainContent}>
                      <BookingPanel
                        description={description}
                        className={css.bookingPanel}
                        listing={currentListing}
                        isOwnListing={isOwnListing}
                        unitType={unitType}
                        onSubmit={handleBookingSubmit}
                        title={bookingTitle}
                        authorDisplayName={authorDisplayName}
                        onManageDisableScrolling={onManageDisableScrolling}
                        monthlyTimeSlots={monthlyTimeSlots}
                        onFetchTimeSlots={onFetchTimeSlots}
                        onFetchTransactionLineItems={
                          onFetchTransactionLineItems
                        }
                        lineItems={lineItems}
                        fetchLineItemsInProgress={fetchLineItemsInProgress}
                        fetchLineItemsError={fetchLineItemsError}
                        publicData={publicData}
                        distanceInMiles={distance}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className={classNames(
                      css.contentContainer,
                      css.otherContentContainer
                    )}
                  >
                    <div>
                      <div className={css.headingMain}>
                        <div className={css.headingMainContent}>
                          <div>
                            <SectionHeading
                              priceTitle={priceTitle}
                              formattedPrice={formattedPrice}
                              richTitle={richTitle}
                              isOwnListing={isOwnListing}
                              isMobile={isMobile}
                              selectedCategory={category}
                              listingCertificate={
                                publicData ? publicData.certificate : null
                              }
                              certificateOptions={certificateOptions}
                              hostLink={hostLink}
                              showContactUser={showContactUser}
                              onContactUser={this.onContactUser}
                            />
                          </div>
                          <div className={css.listingRatingContainer}>
                            <div className={css.listingRating}>
                              <IconCollection name="RATING_STAR" />
                              <h6 className={css.ratingText}>
                                {averageReview} ({reviewCount})
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={classNames(css.otherMainContent)}>
                      <BookingPanel
                        description={description}
                        className={css.bookingPanel}
                        listing={currentListing}
                        isOwnListing={isOwnListing}
                        unitType={unitType}
                        onSubmit={handleBookingSubmit}
                        title={bookingTitle}
                        authorDisplayName={authorDisplayName}
                        onManageDisableScrolling={onManageDisableScrolling}
                        monthlyTimeSlots={monthlyTimeSlots}
                        onFetchTimeSlots={onFetchTimeSlots}
                        onFetchTransactionLineItems={
                          onFetchTransactionLineItems
                        }
                        lineItems={lineItems}
                        fetchLineItemsInProgress={fetchLineItemsInProgress}
                        fetchLineItemsError={fetchLineItemsError}
                        geolocation={geolocation}
                        publicData={publicData}
                        listingId={currentListing.id}
                        address={address}
                        isMobile={isMobile}
                        timeSlots={timeSlots}
                        formetZipLocation={formetZipLocation}
                        distanceInMiles={distance}
                      />
                    </div>
                  </div>
                )}
              </div>
              {!isOwnListing && filterData && filterData.length ? (
                <SimilarItems viewport={viewport} listings={filterData} />
              ) : null}
            </div>
            <Modal
              id="ListingPage.enquiry"
              contentClassName={css.enquiryModalContent}
              isOpen={isAuthenticated && this.state.enquiryModalOpen}
              onClose={() => this.setState({ enquiryModalOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              enquiryForm={true}
            >
              <EnquiryForm
                className={css.enquiryForm}
                submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
                listingTitle={title}
                authorDisplayName={authorDisplayName}
                sendEnquiryError={sendEnquiryError}
                onSubmit={this.onSubmitEnquiry}
                inProgress={sendEnquiryInProgress}
              />
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}
const { number } = PropTypes;
ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([
      LISTING_PAGE_DRAFT_VARIANT,
      LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    ]),
  }).isRequired,

  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }),

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  onCloseListing: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendEnquiryInProgress,
    sendEnquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    enquiryModalOpenForListingId,
    getAllCategory,
    timeSlots,
  } = state.ListingPage;

  const { currentUser, currentUserListing } = state.user;
  const pageListings = getListingsById(state, getAllCategory);

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    timeSlots,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    currentUserListing,
    pageListings: pageListings,
    marketPlaceData: state.marketplaceData,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: (bookingData, listingId, isOwnListing) =>
    dispatch(fetchTransactionLineItems(bookingData, listingId, isOwnListing)),
  onSendEnquiry: (listingId, message) =>
    dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onCloseListing: listingId => dispatch(closeListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

export default ListingPage;
